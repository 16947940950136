import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWays, isLoading as isWayLoading } from "../store/way";

import Cookies from "js-cookie";
import {
  fetchFiltredData,
  filterCategoriesAction,
  getFilterIsLoading,
  setRegionsData,
} from "../store/filter";
import HashLoader from "react-spinners/HashLoader";

import {
  useGetAllRoutesQuery,
  useGetRoutesRegionsQuery,
} from "../store/RTKApi/routes";
import { getAllMediasAction, getAllTypesAction } from "../store/events";
import {
  useGetRecordsMediaQuery,
  useGetRecordsTypesQuery,
} from "../store/RTKApi/events";

import { useGetContentQuery, useGetSliderQuery } from "../store/RTKApi/medias";
import { useGetClubEventsCategoriesQuery } from "../store/RTKApi/club";
import { authCheck } from "../store/auth/auth.slice";
import {
  useGetAllGroupsQuery,
  useGetAllSchoolsQuery,
  useGetAllStudentQuery,
  useGetAllTeachersQuery,
  useLazyGetAllSchoolsQuery,
} from "../store/RTKApi/schools";
import {
  setGroups,
  setSchool,
  setStudents,
  setTeachers,
} from "../store/schools";

const AppLoader = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const credentials = useSelector((state) => state.auth.auth);

  const { isLoading: contentMainLoading } = useGetContentQuery({});
  const { isLoading: sliderLoading } = useGetSliderQuery({});
  const filterLoading = useSelector(getFilterIsLoading());

  const {
    data: regionData,
    isError: regionError,
    isLoading: regionsLoading,
  } = useGetRoutesRegionsQuery();
  // Достаем все события ОБЯЗАТЕЛЬНА ФИЛЬТРАЦИЯ

  const { data: categoriesData, isLoading: categoriesLoading } =
    useGetClubEventsCategoriesQuery();

  const {
    data: schoolData,
    isLoading: schoolLoading,
    isSuccess: schoolSuccess,
  } = useGetAllSchoolsQuery(null, {
    skip: credentials === undefined || credentials === "",
  });
  const {
    data: teachersData,
    isLoading: teachersLoading,
    isSuccess: teacherSuccess,
  } = useGetAllTeachersQuery(
    {},
    { skip: credentials === undefined || credentials === "" }
  );
  const {
    data: groupsData,
    isLoading: groupsLoading,
    isSuccess: groupsSuccess,
  } = useGetAllGroupsQuery(null, {
    skip: credentials === undefined || credentials === "",
  });
  const {
    data: studentsData,
    isLoading: studentsLoading,
    isSuccess: studentsSuccess,
    isFetching: studentsFetching,
  } = useGetAllStudentQuery(
    { page: 1, page_size: 1000 },
    { skip: credentials === undefined || credentials === "" }
  );

  // Типы для событий
  const {
    data: typesData,
    isLoading: typesLoading,
    isSuccess: typesSuccess,
    isError: typesError,
  } = useGetRecordsTypesQuery();
  //Медиа для событий
  const { data: evtMediaData, isLoading: evtMediaLoading } =
    useGetRecordsMediaQuery();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFiltredData());
  }, []);
  useEffect(() => {
    dispatch(setRegionsData(regionData));
  }, [regionData]);
  useEffect(() => {
    dispatch(authCheck(Cookies.get("access_token")));
  }, [credentials]);
  useEffect(() => {
    if (credentials !== undefined && credentials !== "") {
      if (credentials === Cookies.get("access_token") && credentials !== "") {
        if (schoolSuccess) {
          dispatch(setSchool(schoolData));
        }
      }
    }
  }, [credentials, schoolSuccess]);
  useEffect(() => {
    if (credentials !== undefined && credentials !== "") {
      if (credentials === Cookies.get("access_token") && credentials !== "") {
        if (groupsSuccess) {
          dispatch(setGroups(groupsData));
        }
      }
    }
  }, [credentials, groupsSuccess]);
  useEffect(() => {
    if (credentials !== undefined && credentials !== "") {
      if (credentials === Cookies.get("access_token") && credentials !== "") {
        if (teacherSuccess) {
          dispatch(setTeachers(teachersData));
        }
      }
    }
  }, [credentials, teacherSuccess]);
  useEffect(() => {
    if (credentials !== undefined && credentials !== "") {
      if (credentials === Cookies.get("access_token") && credentials !== "") {
        if (studentsSuccess || !studentsFetching) {
          dispatch(setStudents(studentsData));
        }
      }
    }
  }, [credentials, studentsSuccess, studentsFetching]);
  useEffect(() => {
    // dispatch(setRegionsData(regionData));

    dispatch(getAllTypesAction(typesData));
    dispatch(getAllMediasAction(evtMediaData));
    dispatch(filterCategoriesAction(categoriesData));

    if (
      !typesLoading &&
      !categoriesLoading &&
      !evtMediaLoading &&
      !regionsLoading &&
      !contentMainLoading &&
      !filterLoading &&
      !sliderLoading
    )
      setLoading(false);
  }, [
    regionsLoading,
    typesLoading,
    evtMediaLoading,
    contentMainLoading,
    categoriesLoading,
    filterLoading,
    sliderLoading,
  ]);

  return (
    <>
      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <HashLoader color="#7fcfa5" size={100} />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default AppLoader;

import { Outlet, useParams } from "react-router-dom";
import {
  Slider as MainSlider,
  EventsPanel,
  MultimediaPanel,
  PopularRoutes,
  SearchRouteMain,
  Container,
  NotesPanel,
  Courusel,
} from "../components";
import React from "react";

export const Main = () => {
  const { id } = useParams();

  return id ? (
    <Outlet />
  ) : (
    <>
      <MainSlider />

      <Container>
        <SearchRouteMain />
        <Courusel />
        <PopularRoutes />
        <EventsPanel />
        <NotesPanel />
      </Container>

      <MultimediaPanel title={"Фотогалерея"} type={"img"} />
      <MultimediaPanel title={"Видеоматериалы"} type={"video"} />
    </>
  );
};

export default Main;

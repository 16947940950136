import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useGetAllOutdoorsNotesQuery,
  useLazyGetAllOutdoorsEventsQuery,
  useLazyGetOutDoorEventsByIdQuery,
} from "../../../store/RTKApi/outdoorEvents";
import { NoteCard } from "../../../components/notes/noteCard";

export const WayNotes = () => {
  const { id } = useParams();
  const { data, isSuccess } = useGetAllOutdoorsNotesQuery({ to_route: id });
  const [transform, setTransform] = useState([]);
  const [getEvent] = useLazyGetOutDoorEventsByIdQuery();
  // console.log(data);
  // useEffect(() => {
  //   if (isSuccess) {
  //     Promise.all(
  //       data.results.map((note) => getEvent(note.to_outdoor_event))
  //     ).then((events) => {
  //       console.log(events);
  //       setTransform(
  //         events.map((event) => ({
  //           ...data.results.find(
  //             (note) => note.to_outdoor_event === event.data.id
  //           ),
  //           date: event.data.date_leave_moscow,
  //         }))
  //       );
  //     });
  //   }
  // }, [isSuccess]);

  return (
    <>
      {isSuccess && data.count > 0 ? (
        <div className="flex flex-col gap-4">
          {data.results.map((note) => (
            <NoteCard note={note} onRoute={true} />
          ))}
        </div>
      ) : (
        <h2 className=" mb-5">Заметки к данному маршруту отсутствуют</h2>
      )}
    </>
  );
};

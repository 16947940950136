import React from "react";
import { OrganizationEventsListItem } from "./organizationEventsListItem";

export const OrganizationEventsList = ({ events }) => {
  return (
    <div className=" mb-20">
      {events?.map((event) => (
        <OrganizationEventsListItem event={event} key={event.id} />
      ))}
    </div>
  );
};

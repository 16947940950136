import { useLockBodyScroll } from "@uidotdev/usehooks";

export const Modal = ({ children }) => {
  useLockBodyScroll();
  return (
    <div className=" fixed top-0 left-0 bg-black z-50 py-28 bg-opacity-60 w-screen h-screen flex justify-center items-stretch overflow-y-scroll">
      <div className=" bg-white shadow-md p-6 relative w-[90%] md:w-[66%] rounded-md  h-fit">
        {children}
      </div>
    </div>
  );
};

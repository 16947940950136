import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import cookieMiddleware from "../middleware/cookie";
import Cookies from "js-cookie";
import config from "../../config.json";

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.baseUrl + '/auth/',
        prepareHeaders: (headers) => {
            const accessToken = Cookies.get("access_token");
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        loginUser: build.mutation({
            query: (body) => ({
                url: 'token/',
                method: 'POST',
                body
            })
        }),
    }),
})

export const {
    useLoginUserMutation
} = authApi
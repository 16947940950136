import { createSlice } from "@reduxjs/toolkit";
import Cookies from 'js-cookie';
import Rtk_utils from "../../utils/rtk_utils";


const authSlice = createSlice({
    name: "auth",
    initialState: {
        auth: ''
    },

    reducers: {
       authCheck: (state, action) => {
           state.auth = action.payload
       },
       logout: (state, action) => {
           state.auth = ''
           Rtk_utils.DeleteCookie('access_token')
           Rtk_utils.DeleteCookie('refresh_token')
       }
    },
});

const { actions, reducer: authReducer } = authSlice;

export const { authCheck, logout } = actions;

export default authReducer;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-flip";
import { useResize } from "../../hooks/useResize";
import "./style.css";
import {
  Autoplay,
  Navigation,
  EffectFade,
  EffectFlip,
  EffectCreative,
} from "swiper/modules";
import { vector6, arrow } from "../../images/mainSlider";
import { Container } from "../container";
import { useGetSliderQuery } from "../../store/RTKApi/medias";

export const Slider = () => {
  const { width } = useResize();
  const { data: slides } = useGetSliderQuery({});

  return (
    <section className="promo mb-0 pt-[80px] md:pt-0">
      <div className="relative min-h-min  w-full   ">
        <Swiper
          // speed={300}
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          effect={"fade"}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".swiper-main-button-next",
            prevEl: ".swiper-main-button-prev",
          }}
          modules={[
            Autoplay,
            Navigation,
            EffectFade,
            EffectFlip,
            EffectCreative,
          ]}
          className="mySwiper h-full  "
        >
          {slides.map(({ header, body, image }, i) => (
            <SwiperSlide key={header} className=" h-auto ">
              {({ isActive }) => (
                <Container>
                  <div
                    className={(!isActive ? "opacity-0" : "") + " h-full  "}
                    style={
                      {
                        // background: ` url(${bgMask}) no-repeat`,
                      }
                    }
                  >
                    {/* <img
                  className=" absolute z-50 top-0 left-0 w-full h-full object-cover "
                  src={bgMask}
                  alt="Слайдер"
                />
                <img
                  className=" absolute z-10 top-0 right-0 w-[70%] h-full object-cover "
                  src={slideTest}
                  alt="Слайдер"
                /> */}
                    <div className=" w-full h-full  pb-32  pt-40">
                      <div
                        className={
                          " h-[100%]  w-full absolute top-0   mt-20 md:mt-0   " +
                          (width < 2000
                            ? " left-0 "
                            : " left-1/2 -ml-[1000px] max-w-[2000px]")
                        }
                      >
                        <img
                          className=" absolute z-[0]  bottom-[30%] w-2/3 max-h-40 xl:max-h-none max-w-[250px] left-0 h-1/3  md:h-[50%] sm:w-1/3 lg:h-[45%] sm:bottom-[20%] md:w-1/3 lg:w-1/3 lg:bottom-[10%] md:max-w-sm  "
                          src={vector6}
                          alt="Слайдер"
                        />
                      </div>
                      <div className=" w-full h-full z-[100]    ">
                        <div className={"flex gap-5 justify-between"}>
                          <div className=" md:w-2/3 lg:w-5/12 max-w-xl h-full pb-14 md:pb-14 lg:pb-20 relative bg-opacity-80  lg:bg-opacity-0 rounded-md bg-white p-2">
                            {" "}
                            <h2 className=" font-rubik text-[24px] md:text-[24px] xl:text-4xl font-semibold leading-[32px]  xl:leading-tight text-[#0F5E2F] mb-6 ">
                              {header}
                            </h2>
                            <p className=" font-rubik text-sm md:text-base font-normal  md:leading-[28px] text-[#151515] ">
                              {body}
                            </p>
                            <div className=" absolute bottom-2 md:left-2 right-2  justify-start flex items-center  gap-2 z-10">
                              <button className="swiper-main-button-prev border rounded-full bg-opacity-80 w-[42px] h-[42px] flex justify-center items-center bg-white">
                                <img src={arrow} alt="" />
                              </button>
                              <button className="swiper-main-button-next rotate-180 border rounded-full bg-opacity-80 w-[42px]  flex justify-center items-center h-[42px] bg-white">
                                <img src={arrow} alt="" />
                              </button>
                            </div>
                          </div>
                          <div className=" w-5/6 mask  h-48  lg:w-7/12 2xl:max-w-[1300px]  md:h-full  2xl:max-h-[700px] xl:max-h-[600px]  lg:max-h-[550px] md:max-h-[450px] max-h-[400px] absolute right-0 top-0 -z-10">
                            <img
                              className="h-full w-full "
                              src={image}
                              alt=""
                            />
                            {/* <Mask /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <Container className={"z-[100] mt-24 lg:mt-32 h-full"}>
                  <div className=" z-50  md:w-6/12 xl:w-1/3 p-4 mt-10 md:mt-0 bg-black bg-opacity-80  rounded-md md:bg-opacity-0">
                    <h2 className=" font-rubik text-[24px] md:text-3xl lg:text-5xl font-semibold leading-[32px]  lg:leading-[60px] text-[#0F5E2F] mb-6">
                      {title}
                    </h2>
                    <p className=" font-rubik text-sm md:text-base font-normal  md:leading-[28px] text-[#151515] ">
                      {discription}
                    </p>
                  </div>
                  <div className="  justify-start flex items-center mt-5 gap-2">
                    <button className="swiper-main-button-prev border rounded-full bg-opacity-80 bg-white">
                      <ChevronLeftIcon className=" h-10 w-10" />
                    </button>
                    <button className="swiper-main-button-next rotate-180 border rounded-full bg-opacity-80 bg-white">
                      <ChevronLeftIcon className=" h-10 w-10" />
                    </button>
                  </div>
                </Container> */}
                  </div>
                </Container>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

import { useSelector } from "react-redux";
import { calendarIcon } from "../../images";
import { getTypeEventById } from "../../store/events/selectors";

export const EventBlockItem = ({ note }) => {
  const type = useSelector(getTypeEventById(note.type));

  return (
    <div className="shadow-md flex flex-col h-full">
      <img
        className=" w-full h-80 object-cover group-hover:opacity-80 transition duration-300 "
        src={note.poster}
        alt={note.description}
      />
      <div className="p-4 grow ">
        <div className=" flex justify-between text-text-gray  text-[14px] leading-[21px] font-semibold font-rubik mt-1 ">
          <div className="flex justify-start items-center gap-2">
            <img src={calendarIcon} alt="Новость" className=" " />
            <span className="">{note.to_date}</span>
            {/* <img src={eyeIcon} alt="Новость" className=" ml-2 " />
                <span className="">5</span> */}
          </div>
          <span className=" text-text-primary-green">#{type.title}</span>
        </div>
        <p className="line-clamp-3 text-neutral-600 text-[16px] leading-[27px] font-semibold font-rubik   group-hover:text-text-primary-green transition duration-300 mt-4">
          {note.title}
        </p>
      </div>
    </div>
  );
};

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, FreeMode } from "swiper/modules";
import { NoteCard } from "./noteCard";
import { useResize } from "../../hooks/useResize";

export const NotesSlider = ({ notes }) => {
  const { isScreenMd } = useResize();
  return notes.length !== 0 ? (
    <div className="my-8">
      <Swiper
        slidesPerView={isScreenMd ? 3 : 1}
        // freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Navigation]}
        className="mySwiper"
        loop={true}
        spaceBetween={30}
        centeredSlides={true}
        navigation={{
          nextEl: ".swiper-notes-button-next",
          prevEl: ".swiper-notes-button-prev",
        }}
      >
        {notes.map((el, index) => (
          <SwiperSlide key={index}>
            <NoteCard note={el} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : (
    <div
      className={
        "group p-4 w-full border-2 mt-6 border-dashed border-text-primary-green hover:border-solid font-rubik transition duration-1000"
      }
    >
      Заметок пока что нет
    </div>
  );
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config.json";
import Rtk_utils from "../../utils/rtk_utils";
import Cookies from "js-cookie";

export const clubApi = createApi({
  reducerPath: "clubApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl + "/club/",
    prepareHeaders: (headers) => {
      const accessToken = Cookies.get("access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getClubDocumentsMethodicalDocuments: build.query({
      query: (arg) => {
        const queryString = Rtk_utils.ClearQueryString(arg);
        return {
          url: `documents/methodical-documents/${queryString}`,
          method: "GET",
        };
      },
    }),
    getClubDocumentsMethodicalDocumentsById: build.query({
      query: (id) => `documents/methodical-documents/${id}/`,
    }),
    getClubDocumentsSections: build.query({
      query: () => `documents/sections/`,
    }),
    getClubDocumentsSectionsById: build.query({
      query: (id) => `documents/sections/${id}/`,
    }),
    getClubEventsCategories: build.query({
      query: () => `/events/categories/`,
    }),
    getClubEventsCategoriesById: build.query({
      query: (id) => `/events/categories/${id}/`,
    }),
    getClubEventsLocations: build.query({
      query: () => `/events/locations/`,
    }),
    getClubEventsLocationsById: build.query({
      query: (id) => `/events/locations/${id}/`,
    }),
    getClubEventsMethodological: build.query({
      query: (arg) => {
        const queryString = Rtk_utils.ClearQueryString(arg);
        return {
          url: `/events/methodological-events/${queryString}`,
          method: "GET",
        };
      },
    }),
    getClubEventsMethodologicalById: build.query({
      query: (id) => `/events/methodological-events/${id}/`,
    }),
    getClubInstructionsCategories: build.query({
      query: () => `/instructions/instruction-categories/`,
    }),
    getClubInstructionsCategoriesById: build.query({
      query: (id) => `/instructions/instruction-categories/${id}/`,
    }),
    getClubInstructionsSections: build.query({
      query: () => `/instructions/instruction-categories/`,
    }),
    getClubInstructionsSectionsById: build.query({
      query: () => `/instructions/instruction-categories/`,
    }),
    getClubInstructionsPortalInstructions: build.query({
      query: () => `/instructions/portal-instructions/`,
    }),
    getClubInstructionsPortalInstructionsById: build.query({
      query: (id) => `/instructions/portal-instructions/${id}/`,
    }),
    getClubInstructionsVideo: build.query({
      query: () => `/instructions/video-instructions/`,
    }),
    getClubInstructionsVideoById: build.query({
      query: (id) => `/instructions/video-instructions/${id}/`,
    }),
  }),
});

export const {
  //Методические документы
  useGetClubDocumentsMethodicalDocumentsQuery,

  // Секции
  useGetClubDocumentsSectionsQuery,

  //Категории событий
  useGetClubEventsCategoriesQuery,

  //Локация событий
  useGetClubEventsLocationsQuery,

  //Методологические события
  useGetClubEventsMethodologicalQuery,

  //Инструкция категории
  useGetClubInstructionsCategoriesQuery,

  //Портал инструкции
  useGetClubInstructionsPortalInstructionsQuery,

  //Секция инструкции
  useGetClubInstructionsSectionsQuery,

  //Видео инструкции
  useGetClubInstructionsVideoQuery,

  //По ID
  useGetClubDocumentsMethodicalDocumentsByIdQuery,
  useGetClubDocumentsSectionsByIdQuery,
  useGetClubEventsCategoriesByIdQuery,
  useGetClubEventsLocationsByIdQuery,
  useGetClubEventsMethodologicalByIdQuery,
  useGetClubInstructionsCategoriesByIdQuery,
  useGetClubInstructionsSectionsByIdQuery,
  useGetClubInstructionsPortalInstructionsByIdQuery,
  useGetClubInstructionsVideoByIdQuery,
} = clubApi;

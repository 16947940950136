import Cookies from "js-cookie";
import axios from "axios";
import config from "../../config.json";
import Rtk_utils from "../../utils/rtk_utils";

const cookieMiddleware = (storeAPI) => (next) => async (action) => {
  const accessToken = Cookies.get("access_token");
  const refreshToken = Cookies.get("refresh_token");

  if (
    accessToken &&
    refreshToken &&
    action?.payload?.data?.code === "token_not_valid"
  ) {
    try {
      const res = await axios.post(`${config.baseUrl}/auth/token/refresh/`, {
        refresh: refreshToken,
      });
      Cookies.set("access_token", res?.data?.access);
    } catch (err) {
      Rtk_utils.DeleteCookie("access_token");
      Rtk_utils.DeleteCookie("refresh_token");
      // Может потребоваться дополнительная логика для обработки ошибки, например, перенаправление пользователя на страницу входа
    }
  }
  return next(action);
};

export default cookieMiddleware;

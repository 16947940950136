import React, { useState } from "react";
import { emailIcon, nameIcon, phoneIcon, postIcon } from "../../images";
import "swiper/css/effect-fade";
import "swiper/scss/pagination";
import { CustomButton } from "../../components/ui/form/customButton";
import { InputField } from "../../components/ui";

const topFields = [
  { title: "ФИО:", icon: nameIcon, name: "name", value: "Johny Cage" },
  { title: "Должность:", icon: postIcon, name: "post", value: "Boss" },
  {
    title: "Телефон:",
    icon: phoneIcon,
    name: "phone",
    value: "+7 777 777 77 77",
  },
  { title: "Почта:", icon: emailIcon, name: "email", value: "email@email.su" },
];

export const Profile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({
    name: topFields[0].value,
    post: topFields[1].value,
    phone: topFields[2].value,
    email: topFields[3].value,
  });
  const media =
    "https://imageup.ru/img41/4533448/snimok-ekrana-2023-09-20-v-143656.jpg";

  const handleChange = (target) => {
    setData((prev) => ({ ...prev, [target.name]: target.value }));
  };
  return (
    <section className="account-profile">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="w-full md:w-5/12">
          <img src={media} alt="Фотография профиля" />
        </div>
        <div className="w-full md:w-7/12 ">
          <div className="top-info flex flex-col  justify-stretch flex-wrap pb-4 ">
            {topFields.map(({ title, icon, name, value }) => (
              <div key={title} className="flex gap-4  mt-5 w-full md:w-2/3">
                <div className="w-6 h-6 icon flex justify-center items-center rounded-full min-w-[40px] min-h-[40px]  bg-text-primary-green">
                  <img className="w-6 h-6" src={icon} alt="" />
                </div>
                <div className="top-info__content flex flex-col w-full">
                  <h3 className="text-gray-400">{title}</h3>
                  {isEdit ? (
                    <InputField
                      styles={" w-full !h-[30px]"}
                      value={data[name]}
                      name={name}
                      onChange={handleChange}
                    />
                  ) : (
                    <p>{value}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className=" flex flex-col justify-start  gap-2">
          <CustomButton
            label={isEdit ? "Сохранить" : "Сменить пароль"}
            styles={" w-full sm:w-full lg:min-w-[170px] bg-text-primary-green"}
          />
          <CustomButton
            label={isEdit ? "Отмена" : "Редактировать"}
            styles={
              " w-full sm:w-full lg:min-w-[170px] bg-[#EBEBEB] !text-text-primary"
            }
            onClick={() => {
              setIsEdit((prev) => !prev);
            }}
          />
        </div>
      </div>
    </section>
  );
};

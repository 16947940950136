import { NavLink, useParams } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { routes } from "../routes";

export const Breadcrumbs = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(routes);

  return (
    <div className="hidden md:flex gap-2 inset-0 mb-6">
      {breadcrumbs.map(({ match, breadcrumb, key }) => (
        <div
          className="flex items-center justify-center gap-2 last:after:content-[''] after:content-['>'] "
          key={key}
        >
          <NavLink key={key} to={match.pathname}>
            {breadcrumb}
          </NavLink>
        </div>
      ))}
    </div>
  );
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config.json";
import Cookies from "js-cookie";
import { transformDate } from "../../utils/transformDate";
import { formatDate } from "../../utils/formatDate";
import Rtk_utils from "../../utils/rtk_utils";

export const schoolsApi = createApi({
  reducerPath: "schoolsApi",
  tagTypes: ["Schools", "Students"],
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl + "/schools",

    prepareHeaders: (headers) => {
      const accessToken = Cookies.get("access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getAllSchools: build.query({
      query: () => `/`,
      providesTags: ["Schools"],
    }),
    getAllStudent: build.query({
      query: (args) => {
        const queryString = Rtk_utils.ClearQueryString(args);
        return {
          url: `/students/${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Students"],
      transformResponse: (res) => res.results,
    }),
    getAllTeachers: build.query({
      query: (args) => {
        const queryString = Rtk_utils.ClearQueryString(args);
        return {
          url: `/teachers/${queryString}`,
          method: "GET",
        };
      },
      transformResponse: (res) => res.results,
    }),
    getAllSchoolsById: build.query({
      query: (id) => `/${id}/`,
    }),
    getAllGroups: build.query({
      query: () => `/groups/`,
    }),
    addSchool: build.mutation({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
    }),
    addTeacher: build.mutation({
      query: (body) => ({
        url: "teachers/",
        method: "POST",
        body,
      }),
    }),
    updateTeacher: build.mutation({
      query: (body) => ({
        url: `teachers/${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Schools"],
    }),
    removeTeacher: build.mutation({
      query: (body) => ({
        url: `teachers/${body.id}/`,
        method: "DELETE",
      }),
    }),
    addStudent: build.mutation({
      query: (body) => ({
        url: "students/",
        method: "POST",
        body: { ...body, birth_date: formatDate(body.birth_date) },
      }),
    }),
    updateStudents: build.mutation({
      query: (body) => ({
        url: `students/${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Students"],
    }),
    strongUpdateSchool: build.mutation({
      query: (args) => {
        const { body, id } = args;
        return {
          url: `/${id}/`,
          method: "PUT",
          body,
        };
      },
    }),
    patchSchool: build.mutation({
      query: (args) => {
        const { body, id } = args;
        return {
          url: `/${id}/`,
          method: "PATCH",
          body,
        };
      },
    }),
    deleteSchool: build.mutation({
      query: (id) => {
        return {
          url: `/${id}/`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  //Забрать Все
  useGetAllSchoolsQuery,
  useGetAllStudentQuery,
  useGetAllTeachersQuery,
  useGetAllGroupsQuery,

  //Lazy
  useLazyGetAllSchoolsQuery,
  useLazyGetAllStudentQuery,
  useLazyGetAllTeachersQuery,
  useLazyGetAllGroupsQuery,

  // По ID
  useGetAllSchoolsByIdQuery,
  //Добавть
  useAddSchoolMutation,
  useAddTeacherMutation,
  useAddStudentMutation,

  useUpdateTeacherMutation,
  useUpdateStudentsMutation,
  // Обновить весь объект
  useStrongUpdateSchoolMutation,
  // Обновить поля
  usePatchSchoolMutation,
  // Удалить
  useDeleteSchoolMutation,
  useRemoveTeacherMutation,
} = schoolsApi;

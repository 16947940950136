import React from "react";
import { Call } from "../../../images";

export const Contacts = () => {
  return (
    <section className="support-contacts">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="w-full md:w-5/12">
          <div className="flex rounded-[5px] border-2 border-text-primary-green gap-7 p-6">
            <img className="h-12 w-12" src={Call} alt="" />
            <div className="flex flex-col justify-between">
              <p className="font-semibold text-[16px] text-text-gray">
                Контакты методистов проекта «Маршрут построен»
              </p>
              <p className="font-medium text-2xl text-text-primary-green">
                +7(495)369-38-58, добавочный 342
              </p>
              <p className="text-[16px] text-[#000] mt-3">
                travelschool@edu.mos.ru
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-7/12">
          <h3 className="text-[18px] font-medium text-text-secondary-green my-2">
            Уважаемые гости нашего портала!
          </h3>
          <p className="leading-7">
            Если у вас есть сложность в оформлении необходимого пакета
            документов для организации выездного мероприятия, подготовки и
            проведении выезда, то вы можете обратиться к методистам проекта
            «Маршрут построен»
          </p>
        </div>
      </div>
    </section>
  );
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config.json";
import Cookies from 'js-cookie';


export const newsletterApi = createApi({
    reducerPath: "newsletterApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.baseUrl + '/newsletter/', prepareHeaders: (headers) => {
            const accessToken = Cookies.get("access_token");
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        addSubscription: build.mutation({
            query: (body) => ({
                url: 'subscribe/',
                method: 'POST',
                body
            })
        })
    })
})

export const {

    //Добавить подписку по почте
    useAddSubscriptionMutation,

} = newsletterApi
import { Link } from "react-router-dom";
import { arrowRightIcon } from "../../images";
import { NotesSlider } from "./notesSlider";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { useGetAllNotesQuery } from "../../store/RTKApi/notes";
import { toast, ToastContainer } from "react-toastify";
import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useGetAllOutdoorsNotesQuery } from "../../store/RTKApi/outdoorEvents";

export const NotesPanel = () => {
  const {
    data: notesData,
    isLoading: notesLoading,
    isSuccess: notesSuccess,
    refetch,

    isError,
  } = useGetAllOutdoorsNotesQuery({ page: "1", pageSize: "3" });

  useEffect(() => {
    refetch();
    if (isError) {
      toast.error("Заметки не удалось загрузить", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, []);
  return (
    <>
      <section className="notes relative">
        <div className=" ">
          <div className=" flex flex-col md:flex-row whitespace-nowrap md:items-center">
            <span className="text-text-primary text-2xl  font-medium font-rubik ">
              Заметки
            </span>
            <Link
              to="/notes"
              className="text-text-gray text-xs  font-normsl font-rubik leading-[18px] md:ml-4 flex items-center pt-1"
            >
              ВСЕ ЗАМЕТКИ{" "}
              <img src={arrowRightIcon} alt="ВСЕ СОБЫТИЯ" className=" ml-2 " />
            </Link>
            <div className="hidden md:flex items-center ml-2 pt-1">
              <button className="swiper-notes-button-prev">
                <ChevronLeftIcon className=" h-6 w-6" />
              </button>
              <button className="swiper-notes-button-next rotate-180">
                <ChevronLeftIcon className=" h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
        {notesData && notesData?.results?.length !== 0 && (
          <NotesSlider notes={notesData?.results} />
        )}
      </section>
    </>
  );
};

import React from "react";
import { MethodicalDocumentsList } from "../../../components";

export const Docs = () => {
  return (
    <section className="support-docs ">
      <MethodicalDocumentsList page={2} />
    </section>
  );
};

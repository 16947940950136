import { useParams } from "react-router-dom";

import { useGetOutDoorEventsFormByIdQuery } from "../../../store/RTKApi/outdoorEvents";

export const OutdoorEventBreadcrumb = () => {
  const { id } = useParams();

  const { data: formData, isSuccess: successData } =
    useGetOutDoorEventsFormByIdQuery(id);

  return <>{successData && formData.title}</>;
};

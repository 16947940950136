import React, { useEffect, useState } from "react";
import { calendarIcon } from "../images";
import { transformDate } from "../utils/transformDate";
import { useParams } from "react-router-dom";
import { CustomButton } from "../components/ui";
import { format } from "date-fns";
import { ru } from "date-fns/locale";

import { Breadcrumbs, Container } from "../components";
import {
  useGetRecordsByIdQuery,
  useGetRecordsMediaQuery,
} from "../store/RTKApi/events";
import { useSelector } from "react-redux";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

const EventPage = () => {
  const { id } = useParams();

  const [transformedData, setTransformedData] = useState([]);

  const {
    data: singleRecordData,
    isLoading: singleRecordLoading,
    isSuccess: singeRecordSuccess,
  } = useGetRecordsByIdQuery(id);

  const types = useSelector((state) => state.event.types);
  // const medias = useSelector((state) => state.event.medias) || [];
  const { data: medias } = useGetRecordsMediaQuery();
  const mediasToRecord = medias.filter((m) => m.to_record == id);

  useEffect(() => {
    if (singeRecordSuccess) {
      const typeIndex = types.findIndex(
        (el) => singleRecordData.type === el.id
      );
      const mediaIndex = medias.findIndex(
        (el) => singleRecordData.type === el.id
      );
      const transformedRecord = {
        ...singleRecordData,
        type: types[typeIndex].title,
        media_item: medias[mediaIndex]?.media_item,
        description: medias[mediaIndex]?.description,
      };
      setTransformedData(transformedRecord);
    }
  }, [singleRecordLoading]);

  const formattedDate = transformedData.to_date
    ? format(transformedData.to_date, "d MMMM yyyy", {
        locale: ru,
      })
    : "";

  return (
    <>
      <section className="mt-20   py-8 mb-0 ">
        <Container className="">
          <div className="bg-[#EFF1F0] p-8">
            <Breadcrumbs />
            <div className="flex flex-col h-full gap-6">
              <div className="flex-grow flex flex-col justify-center gap-1">
                <div className=" flex flex-col md:flex-row justify-between items-center">
                  <h2 className=" text-[24px]  text-black md:text-[32px] font-bold leading-[42px] text-center md:text-left">
                    {transformedData?.title}
                  </h2>
                  {/* <CustomButton
                    label={"Оставить заявку"}
                    styles={"  bg-text-primary-green px-4"}
                  /> */}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex justify-start items-center gap-2 text-text-primary text-[14px]">
                  <img src={calendarIcon} alt="Календарь" className=" " />
                  <span className="">{formattedDate}</span>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className=" mb-0 ">
        <Container>
          <div className=" flex flex-col justify-between gap-6 ck-content">
            <div
              dangerouslySetInnerHTML={{ __html: transformedData?.text }}
              className="event-page font-rubik font-normal text-[16px] text-text-primary leading-[28px] "
            ></div>

            {/* <h2 className=" text-black w-2/3 text-[24px] font-medium leading-[22px]">
              Заголовок H2
            </h2> */}
            <div className="flex flex-col md:flex-row  items-start gap-6">
              {/* <div className="w-40 h-40">
                <img
                  src={transformedData?.media_item}
                  alt="Название маршрута"
                  className="object-cover aspect-square "
                />
              </div> */}
              <p className="font-rubik font-normal text-[16px] text-text-primary leading-[28px]">
                {transformedData?.description}
              </p>
            </div>
            {/* <h3 className=" font-rubik font-medium text-[22px] text-text-secondary-green leading-[33px] ">
              Заголовок
            </h3>
            <ul className="list-disc ml-4 font-rubik font-normal text-[16px] text-text-primary leading-[28px]">
              <li>
                С учётом сложившейся международной обстановки, внедрение
                современных методик является качественно новой ступенью модели
                развития. Повседневная практика показывает, что граница обучения
                кадров требует анализа первоочередных требований.
              </li>
              <li>
                С учётом сложившейся международной обстановки, внедрение
                современных методик является качественно новой ступенью модели
                развития. Повседневная практика показывает, что граница обучения
                кадров требует анализа первоочередных требований.
              </li>
            </ul> */}
            {/* <div className=" border-l-[7px] border-text-primary-green">
              <p className="font-rubik font-semibold text-[16px] text-black leading-[27px] pl-4">
                Являясь всего лишь частью общей картины, интерактивные
                прототипы, вне зависимости от их уровня, должны быть
                представлены в исключительно положительном свете. Современные
                технологии достигли такого уровня, что сплочённость команды
                профессионалов требует от нас анализа системы массового участия!
                И нет сомнений, что стремящиеся вытеснить традиционное
                производство, нанотехнологии формируют глобальную экономическую
                сеть и при этом — функционально разнесены на независимые
                элементы. Для современного мира экономическая повестка
                сегодняшнего дня, в своём классическом представлении, допускает
                внедрение форм воздействия.
              </p>
            </div> */}
            {/* <h3 className=" font-rubik font-semibold text-[20px] text-black leading-[26px] ">
              Заголовок
            </h3> */}
            {/* <ul className="  list-decimal ml-4 font-rubik font-normal text-[16px] text-text-primary leading-[28px]">
              <li>
                С учётом сложившейся международной обстановки, внедрение
                современных методик является качественно новой ступенью модели
                развития. Повседневная практика показывает, что граница обучения
                кадров требует анализа первоочередных требований.
              </li>
              <li>
                С учётом сложившейся международной обстановки, внедрение
                современных методик является качественно новой ступенью модели
                развития. Повседневная практика показывает, что граница обучения
                кадров требует анализа первоочередных требований.
              </li>
            </ul> */}
            {/* <table className="table-auto text-left font-rubik font-normal text-[16px] text-text-primary leading-[28px]">
              <thead>
                <tr className=" bg-text-primary-green text-white h-[50px]">
                  <th className=" pl-4">#</th>
                  <th>Cтолбец 1</th>
                  <th>Cтолбец 2</th>
                  <th>Cтолбец 3</th>
                </tr>
              </thead>
              <tbody>
                <tr className=" B] h-[50px]">
                  <td className=" pl-4">1</td>
                  <td>
                    Malcolm Lockyer Malcolm Lockyer Malcolm Lockyer Malcolm
                    Lockyer Malcolm Lockyer Malcolm Lockyer
                  </td>
                  <td>1961</td>
                  <td>1961</td>
                </tr>
                <tr className=" bg-[#EBEBEB] h-[50px]">
                  <td className=" pl-4">2</td>
                  <td>Malcolm Lockyer</td>
                  <td>1961</td>
                  <td>1961</td>
                </tr>
                <tr className="  h-[50px]">
                  <td className=" pl-4">3</td>
                  <td>Malcolm Lockyer</td>
                  <td>1961</td>
                  <td>1961</td>
                </tr>
              </tbody>
            </table> */}
            {mediasToRecord.length > 0 && (
              <section className="notes relative">
                <div className=" ">
                  <div className=" flex whitespace-nowrap items-center">
                    <span className="text-text-primary text-2xl  font-medium font-rubik ">
                      Фото и видеоматериалы
                    </span>
                    <div className="hidden  md:flex items-center ml-2 pt-1">
                      <button className="swiper-multimedia-button-prev">
                        <ChevronLeftIcon className=" h-6 w-6" />
                      </button>
                      <button className="swiper-multimedia-button-next rotate-180">
                        <ChevronLeftIcon className=" h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>

                {/*  мультимедиа слайдер, не будет работать переделать !
              {mediasToRecord && (
                <MultimediaSlider multyArray={mediasToRecord} />
              )} */}
              </section>
            )}
          </div>
        </Container>
      </section>
    </>
  );
};

export default EventPage;

import React, { useLayoutEffect, useState } from "react";
import { Divider } from "../../ui";
import ReactStars from "react-rating-stars-component";
import { TextField } from "@mui/material";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { close } from "../../../images";
import { PhotoUpload } from "./photoUpload";

export const DayForm = ({ datas, dayTitle, dayNumber, onFormChange, handleDelete, star = 0, noEdit }) => {
  const [stars, setStars] = useState(star);
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  useLayoutEffect(() => {
    if (!noEdit) setIsOpen(true);
  }, [noEdit]);

  const handleChange = (data) => {
    onFormChange(dayNumber, data);
  };

  return (
    <div className={"transition-all duration-500  overflow-hidden " + (isOpen ? " max-h-[1000px] delay-0" : " max-h-[80px] ")}>
      <Divider />{" "}
      <div className="day-form">
        <div className="flex items-center gap-5 cursor-pointer" onClick={handleToggleOpen}>
          <ChevronDownIcon className={" w-6 h-6 min-w-[24px] transition-transform " + (isOpen ? "rotate-180" : "")} />
          <h3 className="text-text-secondary-green text-2xl font-medium first-letter:uppercase grow">{dayTitle}</h3>
          {!noEdit && <img className="cursor-pointer" onClick={handleDelete} src={close} alt="remove" />}
        </div>
        <div className="flex gap-4 items-center">
          <div className="flex gap-2 items-center">
            {" "}
            <span>{stars}/5</span>
            <ReactStars
              count={5}
              edit={!noEdit}
              value={stars}
              onChange={(count) => {
                onFormChange(dayNumber, { stars: count });
                setStars(count);
              }}
              size={30}
              activeColor="#ffd700"
            />
          </div>
        </div>
        <TextField
          fullWidth
          minRows={3}
          multiline
          margin="normal"
          label="Комментарий"
          InputProps={{
            readOnly: noEdit,
          }}
          defaultValue={datas?.content || ""}
          onChange={({ target }) => onFormChange(dayNumber, { content: target.value })}
        />
        <PhotoUpload dayImages={datas?.images} onFormChange={noEdit ? null : handleChange} />
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { CustomButton } from "../../components/ui/form/customButton";
import { SelectField } from "../../components/ui/form/selectField";
import { InputField } from "../../components/ui/form/inputField";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { OrganizationEventsList } from "../../components/account/organizationEventsList";
import EventsOrganizationForm from "../../components/account/outdoor/form";
import { useParams } from "react-router-dom";
import { useGetAllOutdoorsEventsQuery } from "../../store/RTKApi/outdoorEvents";

const sortOptions = [
  { label: "Название", value: "title" },
  { label: "Продолжительность", value: "duration" },
];

export const OrganizationEvents = () => {
  const { id } = useParams();
  const [isForm, setForm] = useState(false);
  const [data, setData] = useState({ page: 1, page_size: 4 });
  useEffect(() => {
    if (id !== undefined) {
      setForm(true);
    }
  }, [id]);
  const { data: events } = useGetAllOutdoorsEventsQuery(data);
  const handlePaginate = (name) => {
    if (name === "page_size")
      setData((prev) => ({ ...prev, [name]: data[name] + 4 }));
  };
  return (
    <>
      {isForm ? (
        <EventsOrganizationForm />
      ) : (
        <div className=" flex flex-col ">
          <div className=" flex flex-col justify-start  gap-2 mb-7">
            <CustomButton
              label={"Создать новое мероприятие"}
              styles={
                " w-full sm:w-full lg:min-w-[170px] bg-text-primary-green"
              }
              onClick={() => setForm((prev) => !prev)}
            />
          </div>
          <div className=" mb-4 md:mb-0 w-full">
            <h2 className=" text-text-primary text-[20px] leading-7">
              Список мероприятий
            </h2>
            <div className=" w-full">
              <form className="flex flex-col  lg:flex-row  md:justify-start items-center gap-2  md:w-full w-full">
                <div className=" flex flex-col md:flex-row  justify-center items-center w-full gap-4">
                  {/* <div className=" flex  items-center w-full gap-2  justify-between lg:justify-start">
                    {" "}
                    <span className="text-[#878787] text-sm leading-[24px] font-normal whitespace-nowrap">
                      Сортировать по:{" "}
                    </span>
                    <SelectField
                      styles={" max-w-[220px]  "}
                      name={"sortParam"}
                      options={sortOptions}
                      defaultValue={sortOptions[0]}
                      onChange={() => {}}
                      placeholder={"сортировать по"}
                      //   value={data.sortParam}
                    />
                  </div> */}
                  {/* <div className=" flex  items-center w-full gap-2  justify-between lg:justify-start">
                    {" "}
                    <span className="text-[#878787] text-sm leading-[24px] font-normal whitespace-nowrap">
                      Сортировать по:{" "}
                    </span>
                    <SelectField
                      styles={" max-w-[220px]  "}
                      name={"sortParam"}
                      options={sortOptions}
                      defaultValue={sortOptions[0]}
                      onChange={() => {}}
                      placeholder={"сортировать по"}
                      //   value={data.sortParam}
                    />
                  </div> */}
                </div>

                <div className=" flex flex-col md:flex-row  justify-center items-center w-full gap-4">
                  {" "}
                  <div className=" flex  items-center w-full gap-2  justify-between lg:justify-start">
                    {" "}
                    <span className="text-[#878787] text-sm leading-[24px] font-normal whitespace-nowrap">
                      Сортировать по:{" "}
                    </span>
                    <SelectField
                      styles={" max-w-[220px]  "}
                      name={"sortParam"}
                      options={sortOptions}
                      defaultValue={sortOptions[0]}
                      onChange={() => {}}
                      placeholder={"сортировать по"}
                      //   value={data.sortParam}
                    />
                  </div>
                  <InputField
                    placeholder="Поиск"
                    styles={" w-full !pr-10"}
                    onChange={() => {}}
                    //   value={data.search}
                    name="search"
                  >
                    <div className=" absolute  !text-black  end-2  top-[16px] ">
                      <MagnifyingGlassIcon className=" h-6 w-6 " />
                    </div>
                  </InputField>
                </div>
              </form>
            </div>
            {events?.results && (
              <OrganizationEventsList events={events.results} />
            )}
            {events?.results.length !== events?.count && (
              <CustomButton
                onClick={() => handlePaginate("page_size")}
                label={"Показать еще"}
                styles={
                  " w-full shadow-none border !text-text-gray !border-[#D9D9D9] mb-6"
                }
                textstyle={""}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

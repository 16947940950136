import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Badge } from "../components/ui";
import { useSelector } from "react-redux";
import { transformColor, transformDistance } from "../utils/transformField";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { calendarIcon } from "../images";
import ReactStars from "react-rating-stars-component";
import {
  formatDate,
  formatDateDifferent,
  numberToText,
  transformDate,
} from "../utils/transformDate";
import { ChevronDownIcon, PencilIcon } from "@heroicons/react/20/solid";
import { getNoteById } from "../store/notes";
import {
  Container,
  MultimediaPanel,
  Breadcrumbs,
  DayForm,
} from "../components";
import {
  useGetRouteByIdQuery,
  useLazyGetRouteByIdQuery,
  useLazyGetRoutesOriginByIdQuery,
  useLazyGetRoutesRegionsByIdQuery,
  useLazyGetRoutesTypesByIdQuery,
} from "../store/RTKApi/routes";

import {
  useGetAllOutdoorsEventsQuery,
  useGetAllOutdoorsNotesQuery,
  useGetOutdoorsNoteByIdQuery,
  useLazyGetAllOutdoorsEventsQuery,
} from "../store/RTKApi/outdoorEvents";
import { secToDays, transformDuration } from "../utils/transformDuration";

let title = "Заметка к маршруту ";

export const DynamicNoteBreadcrumb = () => {
  return title;
};

const NotePage = () => {
  const { id } = useParams();

  const { data: note, isSuccess: noteSuccess } =
    useGetOutdoorsNoteByIdQuery(id);

  // route info
  const [getRegion, { data: region, isSuccess: regionSuccess }] =
    useLazyGetRoutesRegionsByIdQuery();
  const [getOrigin, { data: origin, isSuccess: originSuccess }] =
    useLazyGetRoutesOriginByIdQuery();
  const [getType, { data: type, isSuccess: typeSuccess }] =
    useLazyGetRoutesTypesByIdQuery();

  const [getRoute, { data: route, isSuccess: routeSuccess }] =
    useLazyGetRouteByIdQuery();

  useEffect(() => {
    if (noteSuccess) {
      getRoute(note.to_route);
    }
  }, [noteSuccess]);

  useEffect(() => {
    if (routeSuccess) {
      getRegion(route.region);
      getOrigin(route.origin);
      getType(route.type);
      title += `«${route.title}»`;

      return () => {
        title = "Заметка к маршруту ";
      };
    }
  }, [routeSuccess]);

  const duration = useMemo(() => {
    if (regionSuccess || route) {
      return transformDuration(route?.duration);
    }
  }, [routeSuccess, route]);

  const transformedNoteDays = useMemo(() => {
    if (!noteSuccess) return [];
    return Object.entries(note.data.days).map(([key, value]) => ({
      day: key,
      ...note.data.days[key],
    }));
  }, [noteSuccess]);

  return (
    <>
      <section className="mt-20   py-8 mb-0 bg-[#EFF1F0]">
        <Container>
          <Breadcrumbs />
          {routeSuccess && (
            <div className="flex flex-col h-full gap-6">
              <div className="flex-grow flex flex-col justify-center gap-1">
                <h2 className="text-text-primary w-2/3  text-3xl font-bold">
                  {route.title}
                </h2>
                <p
                  className="text-text-gray mt-2"
                  dangerouslySetInnerHTML={{ __html: route.description }}
                ></p>
              </div>
              <div className="flex justify-between">
                {" "}
                <div className="flex justify-between items-center gap-2 w-full">
                  <div className=" flex justify-center items-center gap-4 ">
                    <div className="flex justify-center gap-4 text-text-primary text-[14px] items-center">
                      <div className="flex justify-start items-center gap-2">
                        <img src={calendarIcon} alt="Календарь" className=" " />
                        <span className="">
                          {formatDateDifferent(
                            new Date(note.data.dateStart),
                            true
                          )}{" "}
                          {note.data.dateEnd &&
                            " - " + formatDateDifferent(note.data.dateEnd)}
                        </span>
                      </div>
                      <span>{note.data.stars}/5</span>
                      <div className=" min-w-[160px]">
                        <ReactStars
                          count={5}
                          size={24}
                          activeColor="#E99B26"
                          color={"#B6B4C3"}
                          value={note.data.stars}
                          edit={false}
                        />
                      </div>
                    </div>
                    <div className=" flex justify-center items-center gap-2">
                      <Badge
                        styles={" h-[30px] border-0 bg-white"}
                        textstyle={" text-black text-[12px]"}
                        label={originSuccess ? origin.title : ""}
                      />
                      <Badge
                        styles={" h-[30px] border-[#C4C4C4] bg-white"}
                        textstyle={" text-text-secondary-green"}
                        label={regionSuccess ? region.title : ""}
                      />
                      <Badge
                        styles={" h-[30px]  border-[#55BFFA] bg-white"}
                        label={duration}
                        textstyle={" text-[#55BFFA]"}
                      />
                      <Badge
                        styles={" h-[30px]  border-[#55BFFA] bg-white"}
                        label={transformDistance(route.length)}
                        textstyle={" text-[#55BFFA]"}
                      />
                      <Badge
                        styles={` h-[30px] border-0`}
                        type={route.type}
                        label={typeSuccess ? type.title : ""}
                        textstyle={" text-white"}
                        color={transformColor(route.type)}
                      />
                    </div>
                  </div>
                  <div className="flex justify-start gap-2 text-text-gray  text-[14px] leading-[21px] font-semibold font-rubik mt-1 items-center">
                    <PencilIcon className=" h-4 w-4 fill-text-gray opacity-80" />

                    <span className=" whitespace-nowrap">{route.author}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Container>
      </section>
      <section className="   py-8 mb-0 ">
        <Container>
          <h3 className="text-text-secondary-green text-3xl font-medium first-letter:uppercase grow mb-2">
            Общее описание
          </h3>
          {noteSuccess && (
            <p className="text-xl text-text-primary">{note.data.content}</p>
          )}
          <div className="mt-5">
            {noteSuccess &&
              transformedNoteDays &&
              transformedNoteDays.map((day) => (
                <DayForm
                  star={day.stars}
                  dayTitle={numberToText(day.day)}
                  key={day.day}
                  noEdit={true}
                  datas={day}
                />
              ))}
          </div>
        </Container>
      </section>
    </>
  );
};

export default NotePage;

//  {
//    testDays.map((day, index) => (
//      <>
//        <>
//          <div className=" flex justify-between items-center mt-4">
//            <div
//              className="flex justify-start items-center gap-2 cursor-pointer"
//              onClick={() => handleClick(index)}
//            >
//              <ChevronDownIcon
//                className={
//                  " w-[18px] h-[18px]" + (isOpen[index] ? " rotate-180 " : "")
//                }
//              />
//              <h2 className=" font-rubik font-medium text-[24px] text-text-secondary-green leading-[32px] ">
//                Первый день
//              </h2>
//            </div>
//            <div className="flex justify-start items-center gap-2">
//              <div className="flex justify-start items-center gap-2 text-text-primary text-[14px]">
//                <img src={calendarIcon} alt="Календарь" className=" " />
//                {/*<span className="">{publication_time}</span>*/}
//              </div>
//              {/*<span>{rate}/5</span>*/}
//              <div className=" min-w-[160px]">
//                <ReactStars
//                  count={5}
//                  size={24}
//                  isHalf={true}
//                  emptyIcon={<i className="far fa-star"></i>}
//                  halfIcon={<i className="fa fa-star-half-alt"></i>}
//                  fullIcon={<i className="fa fa-star"></i>}
//                  activeColor="#E99B26"
//                  color={"#B6B4C3"}
//                  // value={rate}
//                  edit={false}
//                />
//              </div>
//            </div>
//          </div>
//          <div className=" w-full h-[1px] bg-[#D9D9D9] mt-4"></div>
//        </>
//        {isOpen[index] && (
//          <div className=" flex flex-col gap-4 py-4 font-rubik font-normal text-[16px] text-text-primary leading-[28px]">
//            <p>
//              В частности, семантический разбор внешних противодействий в
//              значительной степени обусловливает важность системы обучения
//              кадров, соответствующей насущным потребностям. Ясность нашей
//              позиции очевидна: глубокий уровень погружения говорит о
//              возможностях распределения внутренних резервов и ресурсов.
//            </p>
//            <h3 className=" font-rubik font-medium text-[18px] text-text-primary-green leading-[26px] ">
//              Заголовок
//            </h3>
//            <ul className="list-disc ml-4">
//              <li>
//                С учётом сложившейся международной обстановки, внедрение
//                современных методик является качественно новой ступенью модели
//                развития. Повседневная практика показывает, что граница обучения
//                кадров требует анализа первоочередных требований.
//              </li>
//              <li>
//                С учётом сложившейся международной обстановки, внедрение
//                современных методик является качественно новой ступенью модели
//                развития. Повседневная практика показывает, что граница обучения
//                кадров требует анализа первоочередных требований.
//              </li>
//            </ul>
//            <MultimediaPanel />
//          </div>
//        )}
//      </>
//    ));
//  }

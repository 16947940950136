export const getSchoolInfo = () => (state) => state.schools.credentials;

export const getTeachersInfo = (info) => (state) => {
  if (!info) return state.schools.teachers;

  return state.schools.teachers.filter((teach) => {
    return Object.values(teach).some((v) => v && v.toString().toLowerCase().includes(info.toLowerCase()));
  });
};

export const getTeacherById = (id) => (state) => {
  return state.schools.teachers.find((t) => t.id === id);
};

export const getGroupsInfo = () => (state) => state.schools.groups;

export const getStudentsInfo =
  ({ name, id }) =>
  (state) => {
    if (!id) {
      return name ? state.schools.students.filter((el) => el.full_name.toLowerCase().includes(name.toLowerCase())) : state.schools.students;
    } else {
      return state.schools.students.find((s) => s.id === id);
    }
  };

export const getDirector = () => (state) => {
  const school = state.schools.school;
  return [
    {
      label: school?.director?.full_name,
      value: school?.director?.id,
    },
  ];
};

export const getAdvisors = () => (state) => {
  const teachers = state.schools.teachers;
  if (teachers) {
    return teachers?.map((el) => {
      return {
        label: el?.full_name,
        value: el?.id,
      };
    });
  } else {
    return [];
  }
};

export const getEducationProgram = () => (state) => {
  const school = state.schools.school;
  if (school?.programms) {
    return school?.programms.map((el, idx) => {
      return {
        label: el,
        value: idx,
      };
    });
  } else {
    return [];
  }
};

export const getStudentsWithGrade = () => (state) => {
  const students = state.schools.students;
  if (students) {
    return students.map((student) => {
      return {
        value: student.id,
        label: student.full_name,
        grade: student.grade,
      };
    });
  } else {
    return [];
  }
};

import React from "react";

export const InputField = ({
  type,
  placeholder,
  styles,
  children,
  onChange,
  name,
  value,
}) => {
  const cls = `h-[50px] rounded-md p-[15px]  border  my-1 leading-[18px] font-normal text-[14px]  text-text-primary font-rubik placeholder:text-text-gray outline-none`;
  const handleChange = (e) => {
    onChange({ name, value: e.target.value });
  };
  return (
    <div className=" relative w-full ">
      <input
        className={cls + styles}
        type={type || "text"}
        placeholder={placeholder || ""}
        onChange={handleChange}
        value={value || ""}
      />
      {children}
    </div>
  );
};

export default InputField;

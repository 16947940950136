export const transformOutdoorEventStatus = (status) => {
   switch (status) {
      case "PL":
         return "Планируется"
      case "AC":
         return `Активно`
      case "FN":
         return `Завершено`
      case "CN":
         return `Отменено`
      default:
         return "Нет статуса"
   }
};

export const transformOutdoorEventStatusStyle = (status) => {
   switch (status) {
      case "PL":
         return " text-white bg-text-primary-green"
      case "AC":
         return ` text-white bg-[#14D1A4]`
      case "FN":
         return ` text-white bg-[#E99B26]`
      case "CN":
         return ` text-white bg-[#A51600]`
      default:
         return ""
   }
}



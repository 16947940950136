import { createSlice } from "@reduxjs/toolkit";

const schoolsSlice = createSlice({
  name: "schools",
  initialState: {
    credentials: "",
    students: {},
    teachers: [],
    groups: [],
    school: {},
  },

  reducers: {
    schoolsCredentials: (state, action) => {
      state.credentials = action.payload;
    },
    addSchoolData: (state, action) => {
      state.school = action.payload;
    },
    addTeachersData: (state, action) => {
      state.teachers = action.payload;
    },

    addOneTeacher: (state, action) => {
      state.teachers.push(action.payload);
    },
    removedTeacher: (state, action) => {
      state.teachers = state.teachers.filter((t) => t.id !== action.payload);
    },
    updatedTeacher: (state, action) => {
      state.teachers = state.teachers.map((t) =>
        t.id === action.payload.id ? { ...action.payload.teacher } : t
      );
    },
    addOneStudent: (state, action) => {
      state.students.push(action.payload);
    },
    addGroupsData: (state, action) => {
      state.groups = action.payload;
    },
    addStudentsData: (state, action) => {
      state.students = action.payload;
    },
  },
});

const { actions, reducer: schoolsReducer } = schoolsSlice;

export const {
  removedTeacher,
  schoolsCredentials,
  updatedTeacher,
  addSchoolData,
  addTeachersData,
  addGroupsData,
  addStudentsData,
  addOneTeacher,
  addOneStudent,
} = actions;

export default schoolsReducer;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { arrowRightIcon } from "../../images";
import { WaysList } from "./waysList";
import {
  useGetAllRoutesQuery,
  useGetPopularRoutesQuery,
} from "../../store/RTKApi/routes";

export const PopularRoutes = () => {
  const [data, setData] = useState({ page: 1, page_size: 4 });
  const {
    data: waysPopularData,
    isLoading: waysPopularLoading,
    isSuccess: waysPopularSuccess,
  } = useGetPopularRoutesQuery({});
  const {
    data: waysAllData,
    isLoading: waysAllLoading,
    isSuccess: waysAllSuccess,
  } = useGetAllRoutesQuery({
    age_category: "",
    attractions: "",
    author: "",
    description: "",
    duration_min: "",
    duration_max: "",
    length_min: "",
    natural_environment: "",
    page: data.page,
    page_size: data.page_size,
    region: "",
    season: "",
    sources: "",
    tags: "",
    target_audience: "",
    title: "",
    type: "",
  });
  const handlePaginate = (name) => {
    setData((prev) => ({ ...prev, [name]: (data[name] = data[name] + 4) }));
  };
  // waysPopularSuccess....
  return (
    <section className="popular">
      <div className="relative py-2">
        <div className=" flex  flex-col md:flex-row whitespace-nowrap md:items-end">
          <span className="text-text-primary text-2xl leading-none  font-medium font-rubik ">
            Популярные маршруты
          </span>
          <Link
            to="/ways"
            className="text-text-gray text-xs  font-normsl font-rubik leading-[18px] md:ml-4 md:leading-none flex items-center pt-1"
          >
            ВСЕ МАРШРУТЫ{" "}
            <img src={arrowRightIcon} alt="ВСЕ СОБЫТИЯ" className=" ml-2 " />
          </Link>
        </div>
        <div className="flex flex-row  overflow-x-scroll md:flex-row justify-start items-start h-full my-6 gap-4  ">
          {waysPopularLoading ? (
            <div>Загрузка</div>
          ) : (
            <WaysList allWays={waysPopularData} popular={true} />
          )}
        </div>
        {/* <div className="flex justify-center flex-col items-center my-6 gap-2 ">
          <WaysList allWays={waysAllData} handleClickPage={handlePaginate} />
        </div> */}
        {/* список популярных маршрутов с пагинацей */}
      </div>
    </section>
  );
};

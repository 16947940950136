import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { placeMarkerIcon, travelSchoolLogo } from "../images";
import { findIcon } from "../images";
import { InputField, SelectField, CustomButton } from "./ui";
import { useSelector } from "react-redux";

export const SearchRouteMain = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({ search: "", regions: null });
  const storeRegionsData = useSelector((state) => state.filter.regionsData);
  const regionsSelect = storeRegionsData.map((element) => {
    return {
      label: element.title,
      value: element.id,
    };
  });
  const handleChange = (target) => {
    setData({ ...data, [target.name]: target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/ways", { state: data });
  };

  return (
    <div className="flex flex-col py-5 justify-between">
      <div className=" md:py-8 md:px-10 pb-0 relative flex flex-col bg-white gap-4 w-full mb-10  md:mb-20  md:border-2 md:border-dashed border-text-primary-green -mt-[120px] md:-mt-[150px] lg:-mt-[150px] z-[10]">
        <div className=" relative w-full h-full p-4 md:p-0">
          <div className=" absolute border-2 border-dashed border-text-primary-green md:border-none w-full h-[120%] top-0 left-0 "></div>
          <h2 className=" font-rubik font text-[#168743] text-2xl">
            Найдите свой маршрут
          </h2>
          <p className=" font-rubik break-all  text-[#7E7E7E] text-sm font-normal leading-[24px]">
            Добро пожаловать в мир приключений! «Школа путешествий» предлагает
            познавательные маршруты, которые помогут открыть новые горизонты и
            получить незабываемые впечатления. Выбирай увлекательные поездки по
            нашей стране, экскурсии по природным достопримечательностям и
            городской среде, либо создай свой уникальный маршрут и поделись с
            ним с друзьями!
          </p>
        </div>

        <div className=" flex justify-center items-start gap-4  w-full md:pb-6 ">
          <img
            src={travelSchoolLogo}
            alt="Школа путешествий"
            width="231"
            height="82"
            className=" hidden md:block"
          />
          <div className=" relative w-full ">
            <div className=" w-full  md:absolute top-[90%]  md:top-2/3  rounded-3xl  rounded-ss-none shadow-md p-6 bg-gradient-to-r from-text-primary-green to-[#0F5E2F]  ">
              <h2 className=" font-rubik font-semibold w-full text-base  leading-[22px] text-white mb-2 ">
                Поиск маршрута
              </h2>
              <form
                className="  justify-center items-center gap-2 "
                onSubmit={handleSubmit}
              >
                <div className=" flex flex-col md:flex-row w-full items-center gap-4 ">
                  <SelectField
                    styles={" w-full h-[50px] w-full md:max-w-[220px]"}
                    name={"regions"}
                    onChange={handleChange}
                    options={regionsSelect}
                    placeholder={"Город"}
                    value={data.regions || null}
                  >
                    <img
                      src={placeMarkerIcon}
                      alt="Город"
                      className=" inline-block absolute top-3 left-2"
                    />
                  </SelectField>
                  <InputField
                    placeholder="Поиск маршрута"
                    styles={" w-full h-[50px]"}
                    value={data.search}
                    onChange={handleChange}
                    name="search"
                  />
                  <CustomButton
                    styles={
                      " w-full md:w-[50px] min-w-[50px] bg-text-primary-green"
                    }
                  >
                    <div className=" flex justify-center items-center">
                      <img src={findIcon} alt="Поиск" />
                    </div>
                  </CustomButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import axios from "axios";
import Cookies from "js-cookie";
import config from "../config.json";

/* eslint-disable import/no-anonymous-default-export */

const endpoint = "outdoor-events/notes";
class OutdoorEventsService {
  constructor() {}

  async sendMedia(media) {
    const { data } = await axios.post(
      `${config.baseUrl}/${endpoint}/media/create/`,
      media,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("access_token") || ""}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  }
  async sendNote(note) {
    const { data } = await axios.post(
      `${config.baseUrl}/${endpoint}/create/`,
      note,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("access_token") || ""}`,
        },
      }
    );
    return data;
  }
}

export const outdoorEventsService = new OutdoorEventsService();

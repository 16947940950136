import React, { useEffect, useState } from "react";

import { Breadcrumbs, Container, WaysList, YaMap } from "../components";
import { Link } from "react-router-dom";
import {
  ListBulletIcon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { CustomButton, SelectField } from "../components/ui";
import { filterIcon, placeMarkerIcon } from "../images";
import { WaysFilterForm } from "../components/ways/waysFilterForm";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetAllRoutesQuery,
  useGetPopularRoutesQuery,
} from "../store/RTKApi/routes";
import {
  getFilterRegionAction,
  getSearchString,
  clearWaysFilter,
} from "../store/filter";

const sortOptions = [
  { label: "Название", value: "title" },
  { label: "Продолжительность", value: "duration" },
];

function WaysMap() {
  const dispatch = useDispatch();
  const handleClearFilter = () => {
    dispatch(clearWaysFilter());
  };
  const storeRegionsData = useSelector((state) => state.filter.regionsData);
  const regionsSelect = storeRegionsData
    .filter((r) => r.id)
    .map((element) => {
      return {
        label: element.title,
        value: element.id,
      };
    });

  let filter = useSelector((state) => state.filter.queryStringParams);
  let region = useSelector((state) => state.filter.regionPick);
  let search = useSelector((state) => state.filter.searchString);
  // Проверяем на пустоту наш фильтр
  const filterCheck = Object.keys(filter).length === 0 ? (filter = []) : filter;
  //Запрашиваем данные, идет автоматический refetch если филтр поменялся
  const {
    data: waysPopularData,
    isLoading: waysPopularLoading,
    isSuccess: waysPopularSuccess,
  } = useGetPopularRoutesQuery({});
  const {
    data: waysData,
    isLoading: waysLoading,
    isSuccess: waysSuccess,
    isError: waysError,
  } = useGetAllRoutesQuery({
    age_category: filterCheck.age_category,
    attractions: filterCheck.attractions,
    author: "",
    description: "",
    duration_min: filterCheck?.duration_min,
    duration_max: filterCheck?.duration_max,
    length_min: filterCheck?.length_min,
    length_max: filterCheck?.length_max,
    natural_environment: filterCheck?.natural_environment,
    page: "",
    page_size: "100",
    region: region,
    season: filterCheck?.season,
    sources: "",
    tags: filterCheck?.tags,
    target_audience: filterCheck?.target_audience,
    title: search,
    type: filterCheck?.type,
  });
  const [data, setData] = useState({
    search: search || "",
    regions: region || null,
    sortParam: sortOptions[0],
  });
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const handleChange = (target) => {
    if (target.name === "regions")
      dispatch(getFilterRegionAction(target.value?.value));
    if (target.name === "search") dispatch(getSearchString(target.value));
    setData({ ...data, [target.name]: target.value });
  };
  return (
    <>
      {isOpenFilter && (
        <div className="fixed top-0 left-0 bg-white z-50 w-screen h-full  flex-col md:hidden    ">
          <div className="flex flex-col shadow-md m-4 p-4 relative  gap-4">
            <WaysFilterForm />
            <CustomButton
              styles={"  bg-text-primary-green w-full"}
              onClick={() => setIsOpenFilter((prev) => !prev)}
            >
              <div className=" flex justify-center items-center">
                <span className=" font-rubik text-[14px] text-white leading-5 font-medium">
                  Показать ({waysData?.count})
                </span>
              </div>
            </CustomButton>
            <XMarkIcon
              className=" w-6 h-6 fill-black absolute top-4 right-4 rotate-180 cursor-pointer"
              onClick={() => setIsOpenFilter((prev) => !prev)}
            />
          </div>
        </div>
      )}
      <section className="maps mb-0">
        <Container>
          <div className="flex flex-col  justify-between font-rubik">
            <div className="relative pt-32 ">
              <Breadcrumbs />
              <div className=" flex flex-col md:flex-row justify-between items-center md:gap-8 mb-10">
                <h1 className="font-semibold text-[50px] text-black leading-[60px] mb-8 md:mb-0 w-full text-left">
                  Маршруты
                </h1>
                <div className=" w-full flex flex-col md:flex-row justify-center items-center gap-4">
                  <Link
                    to="/ways"
                    className="flex justify-center items-center gap-2 md:hidden lg:flex"
                  >
                    <ListBulletIcon className=" w-6 h-6 fill-[#168743]" />
                    <span className=" text-[#168743]">Списком</span>
                  </Link>
                  <CustomButton
                    styles={
                      " w-full flex justify-between items-center min-w-[200px] border border-text-primary-green"
                    }
                    onClick={() => setIsOpenFilter((prev) => !prev)}
                  >
                    <div className=" flex justify-center items-center p-2 gap-4">
                      <img src={filterIcon} alt="Фильтр" />
                      <span className=" font-rubik text-[14px] text-text-primary leading-5 font-medium">
                        Фильтр
                      </span>
                    </div>
                    <div className=" flex justify-center items-center p-2">
                      <ChevronDownIcon className=" w-4 h-5 fill-black" />
                    </div>
                  </CustomButton>
                  <SelectField
                    styles={" min-w-[220px] h-[50px] my-1"}
                    name={"regions"}
                    onChange={handleChange}
                    options={regionsSelect}
                    placeholder={"Город"}
                    value={data.regions}
                    defaultValue={regionsSelect[0]}
                  >
                    <img
                      src={placeMarkerIcon}
                      alt="Город"
                      className=" inline-block absolute top-3 left-2"
                    />
                  </SelectField>
                </div>
              </div>
              {isOpenFilter && (
                <div className=" border-t border-t-green-700   mb-10 flex flex-col items-center">
                  <WaysFilterForm isMaps={true} />
                  <div className=" w-11/12 h-[2px] bg-[#D9D9D9] "></div>
                  <div className=" flex justify-start items-center w-full p-2">
                    <button
                      className=" text-[14px] leading-[21px] text-text-primary"
                      onClick={handleClearFilter}
                    >
                      X Очистить фильтр
                    </button>
                  </div>
                </div>
              )}
              <YaMap
                center={
                  regionsSelect?.find((reg) => reg.value === region)?.label
                }
                items={waysData}
              />
              <div className=" flex  flex-col md:flex-row whitespace-nowrap md:items-center mt-5">
                <span className="text-text-primary text-2xl  font-medium font-rubik ">
                  Популярные маршруты
                </span>
              </div>
              <div className="flex flex-row  overflow-x-scroll md:flex-row justify-start items-start h-full my-6 gap-4  ">
                <WaysList allWays={waysPopularData} popular={true} />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default WaysMap;

import React from "react";
import { eventBig, Play } from "../../images";
import { useModal } from "../../hooks/useModal";

export const ManualItem = ({ video }) => {
  const { openModal } = useModal();
  return (
    <div
      className=" w-full md:w-[30%] cursor-pointer hover:scale-[1.01]"
      onClick={() => {
        openModal(video.file);
      }}
    >
      <div className="content relative">
        <video
          className="w-full h-[200px] object-cover brightness-[60%] bg-slate-800"
          src={video.file}
          poster={video.poster}
        ></video>
        <div className="wrapper flex justify-center items-center absolute top-0 left-0 w-full h-full ">
          <img src={Play} alt="Видео" />
        </div>
      </div>
      <h4 className="text-[18px] leading-6 mt-4">{video.title}</h4>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import "swiper/css/effect-fade";
import "swiper/scss/pagination";
import { CustomButton } from "../../../components/ui/form/customButton";
import { StaffItem } from "../../../components";
import { InputField } from "../../../components/ui/form/inputField";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { SelectField } from "../../../components/ui/form/selectField";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTeachersInfo } from "../../../store/schools/selectors";

const sortOptions = [
  { label: "Название", value: "title" },
  { label: "Продолжительность", value: "duration" },
  { label: "Продолжительность", value: "duration" },
];

export const Staff = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({ search: "" });

  const teachers = useSelector(getTeachersInfo(data.search));

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.name]: e.value }));
  };

  const isCreate = location.pathname.endsWith("create");
  const isEdit = location.pathname.endsWith("edit");

  return (
    <>
      {isCreate || isEdit ? (
        <Outlet />
      ) : (
        <section className="account-staff">
          <div className="">
            <form className="flex flex-col  md:flex-row  md:justify-start items-center gap-2  md:w-full mb-6 w-full">
              {/* <div className=" flex  items-center w-full gap-2  justify-between md:justify-start">
                {" "}
                <span className="text-[#878787] text-sm leading-[24px] font-normal whitespace-nowrap">
                  Сортировать по:{" "}
                </span>
                <SelectField
                  styles={" max-w-[220px]  "}
                  name={"sortParam"}
                  options={sortOptions}
                  defaultValue={sortOptions[0]}
                  onChange={() => {}}
                  placeholder={"сортировать по"}
                  //   value={data.sortParam}
                />
              </div>

              <div className=" flex   items-center w-full gap-2 justify-between md:justify-start">
                {" "}
                <span className="text-[#878787] text-sm leading-[24px] font-normal whitespace-nowrap">
                  Должность:{" "}
                </span>
                <SelectField
                  styles={" max-w-[220px]   "}
                  name={"sortParam"}
                  options={sortOptions}
                  defaultValue={sortOptions[0]}
                  onChange={() => {}}
                  placeholder={"сортировать по"}
                  //   value={data.sortParam}
                />
              </div> */}

              <InputField placeholder="Поиск" styles={" w-full !pr-10"} onChange={handleChange} value={data.search} name="search">
                <div className=" absolute  !text-black  end-2  top-[16px] ">
                  <MagnifyingGlassIcon className=" h-6 w-6 " />
                </div>
              </InputField>
              <CustomButton
                styles={"  bg-text-primary-green px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80 !mt-0"}
                onClick={() => navigate("create")}
              >
                + Добавить сотрудника
              </CustomButton>
            </form>
          </div>
          <div className=" grid grid-cols-1  md:grid-cols-2  gap-2">
            {teachers.map((item) => (
              <StaffItem
                key={item.id}
                id={item.id}
                name={item.full_name}
                email={item.email}
                phone={item.contact_number}
                post={item.position}
              />
            ))}
          </div>
        </section>
      )}
    </>
  );
};

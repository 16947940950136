import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config.json";
import Cookies from "js-cookie";

export const notesApi = createApi({
  reducerPath: "notesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl + "/routes/notes",
    prepareHeaders: (headers) => {
      const accessToken = Cookies.get("access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getAllNotes: build.query({
      query: () => `/`,
    }),
    getNotesById: build.query({
      query: (id) => `/${id}`,
    }),
    getNotesMedia: build.query({
      query: () => `/notes-media`,
    }),
    getNotesMediaById: build.query({
      query: (id) => `/notes-media/${id}/`,
    }),
  }),
});

export const {
  useGetAllNotesQuery,
  useGetNotesByIdQuery,
  useGetNotesMediaQuery,
  useGetNotesMediaByIdQuery,
} = notesApi;

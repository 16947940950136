import React, { useEffect, useState } from "react";
import {
  useGetRoutesNodesByIdQuery,
  useGetRoutesPointContentQuery,
} from "../../../store/RTKApi/routes";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useModal } from "../../../hooks/useModal";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import { Modal } from "../../../components";

export const TimeLineItem = ({ index, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: point, isSuccess } = useGetRoutesNodesByIdQuery(id);
  const { data: pointsContent } = useGetRoutesPointContentQuery();
  const { openModal } = useModal();
  const pointContent =
    pointsContent?.filter((p) => p.to_point === point?.id) || [];
  return (
    <>
      <SkeletonTheme baseColor="#2fac662e" highlightColor="#fffffff14">
        <div className="relative border-l border-l-[#B01515] border-dashed last:border-opacity-0    pb-6">
          <div className="ml-[30px]">
            <div className="absolute w-[30px] h-[30px]  rounded-full top-0  -left-[16px]  bg-text-primary-green flex justify-center items-center ">
              <span className=" text-white text-[14px] leading-6">
                {index + 1}
              </span>
            </div>
            {isSuccess ? (
              <div className=" flex justify-between bg-[#e8e8e8] text-black  text-[14px] leading-6 border rounded-lg rounded-ss-none px-3 py-2">
                <span>{point?.name}</span>
                <button className="w-6 h-6" onClick={() => setIsOpen(true)}>
                  <PlusIcon className=" w-6 h-6 fill-[#7E7E7E]" />
                </button>
              </div>
            ) : (
              <div className=" bg-[#e8e8e8] text-black  text-[14px] leading-6 border rounded-lg rounded-ss-none px-3 py-2">
                <Skeleton width={"100%"} />
              </div>
            )}
          </div>
          {isOpen && (
            <Modal>
              <div
                className=" absolute top-2 right-2 cursor-pointer "
                onClick={() => setIsOpen(false)}
              >
                <XMarkIcon className=" w-6 h-6 hover:opacity-60 transition duration-300" />
              </div>
              <div className="icon flex justify-start items-center rounded-full gap-2 w-full">
                <h2 className=" text-[22px] leading-[33px] text-text-primary-green font-bold mb-6">
                  {point?.name}
                </h2>
              </div>
              <p className=" text-[16px] leading-[28px] text-text-primary mb-6">
                {point?.description}
              </p>
              <div className=" grid grid-cols-2 md:grid-cols-4 gap-2">
                {pointContent?.map((p) => (
                  <img
                    key={p.id}
                    src={p?.media_item}
                    alt="Изображение точки"
                    className=" aspect-square object-cover w-full h-full"
                    onClick={() => openModal(p?.media_item)}
                  />
                ))}
              </div>
            </Modal>
          )}
        </div>
      </SkeletonTheme>
    </>
  );
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config.json";
import Rtk_utils from "../../utils/rtk_utils";
import Cookies from "js-cookie";

export const routesApi = createApi({
  reducerPath: "routesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl + "/routes/",
    prepareHeaders: (headers) => {
      const accessToken = Cookies.get("access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Routes"],
  endpoints: (build) => ({
    getAllRoutes: build.query({
      query: (arg) => {
        const queryString = Rtk_utils.ClearQueryString(arg);
        return {
          url: encodeURI(queryString),
          method: "GET",
        };
      },
      //не трогать тут подписка по тэгу на ID в массиве
      providesTags: (result) =>
        result
          ? [
              { type: "Routes", id: "LIST" },
              ...result.results.map(({ id }) => ({ type: "Routes", id })),
            ]
          : [{ type: "Routes", id: "LIST" }],
    }),
    getPopularRoutes: build.query({
      query: (arg) => {
        const queryString = Rtk_utils.ClearQueryString(arg);
    
        return {
          url: `popular/${queryString}`,
          method: "GET",
        };
      },
      transformResponse: (res) => {
        return {
          results: res,
        };
      },
    }),
    getRouteById: build.query({
      query: (id) => `${id}/`,
    }),
    getRoutesAgeCategories: build.query({
      query: () => `age-categories/`,
    }),
    getRoutesAgeCategoriesById: build.query({
      query: (id) => `age-categories/${id}/`,
    }),
    getRoutesAttractions: build.query({
      query: () => `attractions/`,
    }),
    getRoutesAttractionsById: build.query({
      query: (id) => `attractions/${id}/`,
    }),
    getRoutesContentItems: build.query({
      query: (arg) => {
        const queryString = Rtk_utils.ClearQueryString(arg);
        return {
          url: `content-items${queryString}`,
          method: "GET",
        };
      },
    }),
    getRoutesContentItemsById: build.query({
      query: (id) => `content-items/${id}`,
    }),
    getRoutesNaturalEnvironments: build.query({
      query: () => `natural-environments/`,
    }),
    getRoutesNaturalEnvironmentsById: build.query({
      query: (id) => `natural-environments/${id}`,
    }),
    getRoutesOrigin: build.query({
      query: () => `origin/`,
    }),
    getMainRoutesOrigin: build.query({
      query: () => `main-page-origins/`,
    }),
    getRoutesOriginById: build.query({
      query: (id) => `origin/${id}`,
    }),
    getRoutesRegions: build.query({
      query: () => `regions/`,
    }),
    getRoutesRegionsById: build.query({
      query: (id) => `regions/${id}`,
    }),
    getRoutesSeasons: build.query({
      query: () => `seasons/`,
    }),
    getRoutesSeasonsById: build.query({
      query: (id) => `seasons/${id}/`,
    }),
    getRoutesTags: build.query({
      query: () => `tags/`,
    }),
    getRoutesTagById: build.query({
      query: (id) => `tags/${id}/`,
    }),
    getRoutesTargetAudiences: build.query({
      query: () => `target-audiences/`,
    }),
    getRoutesTargetAudiencesById: build.query({
      query: (id) => `target-audiences/${id}/`,
    }),
    getRoutesTypes: build.query({
      query: () => `types/`,
    }),
    getRoutesTypesById: build.query({
      query: (id) => `types/${id}/`,
    }),
    getRoutesNodes: build.query({
      query: (arg) => {
        const queryString = Rtk_utils.ClearQueryString(arg);
        return {
          url: `nodes/${queryString}`,
          method: "GET",
        };
      },
    }),
    getRoutesNodesById: build.query({
      query: (id) => `nodes/${id}/`,
    }),
    getRoutesMedias: build.query({
      query: () => `notes-media/`,
    }),
    getRoutesMediasById: build.query({
      query: (id) => `notes-media/${id}/`,
    }),
    getRoutesPointContent: build.query({
      query: () => `point-content/`,
    }),
  }),
});

export const {
  useGetAllRoutesQuery,
  useGetPopularRoutesQuery,

  //Медиа
  useGetRoutesMediasQuery,
  useGetRoutesMediasByIdQuery,

  //Lazy использовать в крайних слуаях! Исключительно в событиях!
  useLazyGetAllRoutesQuery,
  useLazyGetRoutesNodesByIdQuery,

  //Фильтра
  useGetRoutesAgeCategoriesQuery,
  useGetRoutesAttractionsQuery,
  useGetRoutesContentItemsQuery,
  useGetRoutesNaturalEnvironmentsQuery,
  useGetRoutesOriginQuery,
  useGetRoutesRegionsQuery,
  useGetRoutesSeasonsQuery,
  useGetRoutesTargetAudiencesQuery,
  useGetRoutesTypesQuery,
  useGetMainRoutesOriginQuery,
  //Фильтра

  //Поиск по ID
  useGetRouteByIdQuery,
  useLazyGetRouteByIdQuery,
  useGetRoutesAgeCategoriesByIdQuery,
  useGetRoutesAttractionsByIdQuery,
  useGetRoutesContentItemsByIdQuery,
  useGetRoutesNaturalEnvironmentsByIdQuery,
  useGetRoutesOriginByIdQuery,
  useLazyGetRoutesOriginByIdQuery,
  //Location
  useGetRoutesRegionsByIdQuery,
  useLazyGetRoutesRegionsByIdQuery,
  //
  useGetRoutesSeasonsByIdQuery,
  useGetRoutesTagByIdQuery,
  useGetRoutesTargetAudiencesByIdQuery,
  useGetRoutesTypesByIdQuery,
  useLazyGetRoutesTypesByIdQuery,
  useGetRoutesNodesByIdQuery,
  // ID
  //Points
  useGetRoutesPointContentQuery,

  //Для карты наверное
  useGetRoutesNodesQuery,
} = routesApi;

import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetOutDoorEventsDocsQuery,
  useGetOutDoorEventsFormByIdQuery,
  usePatchStatusOutDoorEventMutation,
} from "../../../store/RTKApi/outdoorEvents";
import { calendarOutdoor } from "../../../images";
import { locationOutdoor } from "../../../images";
import { messageOutdoor } from "../../../images";
import { phoneOutdoor } from "../../../images";
import { pdfRed } from "../../../images";
import { CustomButton, Divider } from "../../ui";
import { Autocomplete, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const EventOrganizationSuccessForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const { data: formData, isSuccess: successData } =
    useGetOutDoorEventsFormByIdQuery(id);
  const [patchStatus, { data: statusData, isSuccess: statusSuccess }] =
    usePatchStatusOutDoorEventMutation();
  const { data: docsData, isSuccess: docsSuccess } =
    useGetOutDoorEventsDocsQuery(formData?.id, {
      pollingInterval: 5000,
    });

  const [statusState, setStatusState] = useState({
    status: { label: "", value: "" },
  });
  const edu = [
    { label: "СПО", value: "SPO" },
    { label: "СОО", value: "SOO" },
    { label: "ООО", value: "OOO" },
    { label: "НОО", value: "NOO" },
  ];
  const status = [
    { label: "Планируется", value: "PL" },
    { label: "Подтверждено", value: "CF" },
    { label: "На выезде", value: "AC" },
    { label: "Завершено", value: "FN" },
    { label: "Отменено", value: "CN" },
  ];

  useEffect(() => {
    if (successData) {
      const transformedStatusIndex = status.findIndex(
        (el) => el.value === formData.status
      );
      setStatusState({
        status: {
          label: status[transformedStatusIndex].label,
          value: status[transformedStatusIndex].value,
        },
      });
    }
  }, [successData]);

  const [patchData, setPatchData] = useState({
    route: 0,
    date_leave_moscow: "2023-09-21T16:14:43.912Z",
    date_return_moscow: "2023-09-21T16:14:43.913Z",
    leader: 0,
    accompany: [0],
    destination_address: "string",
    educational_program: "string",
    bus_ordered: true,
    food_ordered: true,
    students_amount: 0,
  });

  const handleStatus = ({ name, value }) => {
    if (!!value) {
      const transformedStatusIndex = status.findIndex(
        (el) => el.value === value.value
      );
      setStatusState({
        status: {
          label: status[transformedStatusIndex].label,
          value: status[transformedStatusIndex].value,
        },
      });
      patchStatus({
        id: formData.id,
        body: {
          status: value.value,
        },
      });
    }
  };

  return (
    <div className={"flex flex-col gap-[10px] text-[14px] font-rubik"}>
      {formData && (
        <>
          <div className={"flex flex-col gap-[24px] "}>
            <div className={"flex justify-between items-center"}>
              <h2 className={"text-[24px] "}>
                <Link
                  className="text-text-primary-green underline"
                  to={`/ways/${formData.route}`}
                >
                  {formData.title}
                </Link>{" "}
                {}
              </h2>
              <div className={"flex gap-[5px]"}>
                {/*<span className={'text-gray-400'}>Статус:</span>*/}
                {/*<span*/}
                {/*    className={''}>{status.filter(stat => stat.label === formData.status)[0].value}*/}
                {/*</span>*/}
                <Autocomplete
                  onChange={(_, value) =>
                    handleStatus({ name: "status", value: value })
                  }
                  value={statusState.status}
                  options={status}
                  sx={{ width: 200, maxWidth: 400, borderRadius: "5px" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Cтатус:" />
                  )}
                />
              </div>
            </div>
            <div className={"flex gap-[66px] items-center"}>
              <div className={"flex gap-[11px]"}>
                <div className={"flex  items-start"}></div>
                <div className={"flex flex-col"}>
                  <span className={"text-gray-400"}>Дата выезда:</span>
                  <span>
                    {new Date(formData.date_leave_moscow).toLocaleDateString()}
                  </span>
                </div>
              </div>

              <div className={"flex gap-[11px]"}>
                <div className={"flex  items-start"}>
                  <img src={calendarOutdoor} />
                </div>
                <div className={"flex flex-col"}>
                  <span className={"text-gray-400"}>Дата возврещния:</span>
                  <span>
                    {new Date(formData.date_return_moscow).toLocaleDateString()}
                  </span>
                </div>
              </div>

              <div className={"flex gap-[11px]"}>
                <div className={"flex  items-start"}>
                  <img src={locationOutdoor} />
                </div>
                <div className={"flex flex-col"}>
                  <span className={"text-gray-400"}>Пункт назначения:</span>
                  <span>{formData.destination_address}</span>
                </div>
              </div>
            </div>
          </div>
          <Divider />

          <div className={"flex gap-[66px] items-center"}>
            <div className={"flex flex-col"}>
              <div className={"flex gap-[11px] items-center"}>
                <span className={"text-gray-400"}>Количество:</span>
              </div>
              <div>
                <span>{formData.students_amount}</span>
              </div>
            </div>

            {/*<div className={'flex flex-col'}>*/}
            {/*    <div className={'flex gap-[11px] items-center'}>*/}
            {/*        <span className={'text-gray-400'}>Группа / класс:</span>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <span>10А класс, 9В класс, группа 34 </span>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>

          <Divider />
          <div className={"flex flex-col gap-[24px]"}>
            <div className={"flex justify-between items-center"}>
              <span className={"text-gray-400"}>
                Ответсвенные сопровождающих:
              </span>
            </div>
            <div className={"flex gap-[20px]"}>
              {formData.accompany.map((el) => {
                return (
                  <div className={"flex gap-[66px] items-center"}>
                    <div className={"flex flex-col gap-[10px]"}>
                      <h2 className={"text-[16px]"}>{el.full_name}</h2>
                      <div className={"flex gap-[10px]"}>
                        <img src={phoneOutdoor} />
                        <span>{el.contact_number}</span>
                      </div>
                      <div className={"flex gap-[10px]"}>
                        <img src={messageOutdoor} />
                        <span>{el.email}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/*<Divider/>*/}
            {/*<div className={'flex flex-col gap-[24px]'}>*/}
            {/*    <div className={'flex flex-col'}>*/}
            {/*        <span className={'text-gray-400'}>Образовательная программа:</span>*/}
            {/*        {formData.educational_program.map(el => {*/}
            {/*            const index = edu.findIndex(program => program.value === el)*/}
            {/*            return (*/}
            {/*                <span className={''}>{edu[index].label}</span>*/}
            {/*            )*/}
            {/*        })}*/}
            {/*    </div>*/}
            {/*</div>*/}
            <Divider />
            <div className={"flex flex-col gap-[24px] "}>
              <div className={"flex items-center gap-[66px]"}>
                <div className={"flex gap-[10px] items-center"}>
                  <span className={"text-gray-400"}>Автобус</span>
                  <span className={""}>
                    {formData.bus_ordered ? "Да" : "Нет"}
                  </span>
                </div>
                <div className={"flex gap-[10px] items-center"}>
                  <span className={"text-gray-400"}>Заказ питания</span>
                  <span className={""}>
                    {formData.food_ordered ? "Да" : "Нет"}
                  </span>
                </div>
              </div>
              <div className={"flex mt-[20px] mb-[20px] items-center relative"}>
                <CustomButton
                  styles={
                    "  bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
                  }
                  onClick={() => navigate("../addNote")}
                >
                  Написать заметку
                </CustomButton>
                <a
                  className={"w-[100%] mx-[10px] max-w-[181px]"}
                  href={docsData?.documents}
                  target={"_blank"}
                >
                  <Button
                    disabled={!docsData?.documents}
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "items-center",
                      background: "transparent",
                      width: "100%",
                      maxWidth: 181,
                      padding: "14px 10px",
                      boxShadow: "none",
                      border: "1px solid black",
                      borderRadius: "5px",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "transparent", // Цвет фона при наведении
                        color: "black", // Цвет текста при наведении
                      },
                    }}
                    variant="contained"
                  >
                    <img src={pdfRed} />
                    {!docsData?.documents ? "Загрузка" : "Скачать"}
                  </Button>
                </a>
                <span
                  className={
                    "text-[20px] text-red-500 absolute bottom-0 translate-y-[25px]"
                  }
                >
                  {!docsData?.documents
                    ? "Требуется статус подтверждения!"
                    : null}
                </span>

                <CustomButton
                  styles={
                    "  bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
                  }
                  onClick={() => navigate(`../edit/`)}
                >
                  Редактировать
                </CustomButton>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventOrganizationSuccessForm;

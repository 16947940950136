import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config.json";
import Rtk_utils from "../../utils/rtk_utils";
import Cookies from 'js-cookie';

export const eventsApi = createApi({
    reducerPath: 'eventsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.baseUrl + '/events/', prepareHeaders: (headers) => {
            const accessToken = Cookies.get("access_token");
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        getAllRecords: build.query({
            query: (arg) => {
                const queryString = Rtk_utils.ClearQueryString(arg)
                return {
                    url: `records/${queryString}`,
                    method: 'GET',
                }
            }
        }),
        getRecordsById: build.query({
            query: (id) => `records/${id}/`
        }),
        getRecordsTypes: build.query({
            query: () => `types/`
        }),
        getRecordsTypesById: build.query({
            query: (id) => `types/${id}/`
        }),
        getRecordsMedia: build.query({
            query: () => `media/`
        }),
        getRecordsMediaById: build.query({
            query: (id) => `media/${id}`
        }),
        getAllRecordsDates: build.query({
            query: (arg) => {
                const queryString = Rtk_utils.ClearQueryString(arg)
                return {
                    url: `records/dates/${queryString}`,
                    method: 'GET',
                }
            }
        }),
    })
})

export const {
    //Получить все события
    useGetAllRecordsQuery,
    useLazyGetAllRecordsQuery,
    //Получаем типы
    useGetRecordsTypesQuery,
    //получаем медиа
    useGetRecordsMediaQuery,

    //Получаем по ID
    useGetRecordsByIdQuery,
    useGetRecordsTypesByIdQuery,
    useGetRecordsMediaByIdQuery,

    //Получаем даты событий
    useGetAllRecordsDatesQuery

} = eventsApi